
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'StaffHolder',
  data () {
    return {
      search: '',
      headers: [
        {
          text: this.$t('name'),
          sortable: false,
          value: 'name'
        },
        {
          text: this.$t('roles'),
          sortable: false,
          value: 'role'
        },
        { value: 'actions', sortable: false }
      ],
      dialog: false,
      validStaffForm: false,
      staffForm: {
        email: '',
        emailRules: [
          (v: string) => !!v || this.$t('email-required'),
          (v: string) => /.+@.+/.test(v) || this.$t('email-not-valid')
        ],
        role: [],
        roleRules: [
          (v: string) => !!v || this.$t('user-role-required')
        ]
      },
      snackbar: {
        visible: false,
        color: 'light',
        text: ''
      }
    }
  },
  created () {
    const where = [
      ['club', '==', this.$route.params.id]
    ]
    this.$store.dispatch('StaffModule/fetchAndAdd', { clauses: { where } })
  },
  methods: {
    openDialogStaff () {
      this.dialog = true
    },
    sendInvitation () {
      firebase.firestore().collection('invitations').add({
        club: this.$route.params.id,
        role: this.staffForm.role
      }).then((docRef) => {
        const actionCodeSettings = {
          url: process.env.VUE_APP_CLUB_DOMAIN_PATH + '/register/byinvitation/' + docRef.id,
          handleCodeInApp: true
        }
        firebase.auth().sendSignInLinkToEmail(this.staffForm.email, actionCodeSettings).then(() => {
          this.snackbar.visible = true
          this.snackbar.color = this.$t('success') as string
          this.snackbar.text = this.$t('invitation-sent') as string
        })
      }).catch((error) => {
        this.snackbar.color = 'red'
        this.snackbar.text = error.message
        this.snackbar.visible = true
      })
    },
    unlink (id: string) {
      this.$store.dispatch('StaffModule/delete', id)
    }
  }
}
